// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-3!@fortawesome/fontawesome-free/css/all.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css);"]);
exports.push([module.id, "@import url(https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css);"]);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "html{overflow-y:hidden!important}.component{height:calc(100vh - 210px)!important;box-shadow:0 0 10px #cfcfcf;border-radius:4px;overflow:auto}.campoScript{margin:15px 0 15px 0;padding:20px;width:70%;float:left;color:#fff;background-color:#000;border:1px solid #000;border-radius:4px}p{margin:1em 0 1em 0!important}li,p{-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.cabecalho{box-shadow:10px 5px 5px #bebebe}", ""]);
// Exports
module.exports = exports;
