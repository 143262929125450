<template>
    <div>
        <div class="title is-2 is-unselectable">
            Sejam bem vindos!
        </div>
        <div class="column is-12 is-pulled-left">
            <p>Este guia tem como objetivo mostrar como implementar os componentes, o processo de instalação e build.</p>
            <p>O processo de build tem o método para a página principal que será incluída no Atena como documentação e o processo de geração dos componentes.</p>
            <p> Todos os componentes são feitos em VUE no formato de módulos.</p>
            <p>O projeto está disponibilizado em:</p>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    <p>http://git-sqa.alterdata.matriz/shop/atena/atena-utils</p>
                </div>
            </div>
           
        </div>
    </div>
</template>

<script>
export default {
   
}
</script>

<style>
    
</style>