<template>
    <section class="columns is-pulled-left is-fullheight has-padding-top-5">
        <aside class="menu is-fullheight has-padding-left-40">
            <p class="menu-label">
                Overview
            </p>
            <ul class="menu-list">
                <li @click="viewSelecionada('PaginaInicial')" ><a>Introdução</a></li>
                <li @click="viewSelecionada('Instalacao')"><a>Instalação</a></li>
                <li @click="viewSelecionada('NovoModulo')"><a>Novo Módulo</a></li>
            </ul>
            <p class="menu-label">
                Componentes
            </p>
            <ul class="menu-list">
                <li>
                    <ul>
                        <li @click="viewSelecionada('MensagemRetorno')"><a>Mensagens Retorno</a></li>
                        <li @click="viewSelecionada('ModalLoading')"><a>Modal Loading</a></li>
                    </ul>
                </li>
            </ul>
        </aside>
    </section>
</template>

<script>
export default {
    props: {
        viewSelecionada: Function
    }

}
</script>