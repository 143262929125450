<template>
  <div id="app">
    <div class="column is-12 is-pulled-left is-marginless is-paddingless">
      <figure class="is-pulled-left">
          <img class="image is-48x48 has-padding-10" alt="Vue logo" src="@/assets/logo.png">
        </figure>
        <h1 class="title is-4 has-padding-10">Atena Vue Components</h1>
    </div>
    <hr class="column is-12 is-pulled-left is-marginless is-paddingless has-margin-bottom-10"/>
    <BarraLateral
      :viewSelecionada="viewSelecionada"
      class="column is-3"
    />
    <component 
      :is="componente" 
      class="column is-9 is-pulled-left component"/>
  </div>
</template>

<script>
import PaginaInicial from '@/view/PaginaInicial'
import BarraLateral from '@/view/BarraLateral'
import Instalacao from '@/view/Instalacao'
import NovoModulo from '@/view/NovoModulo'
import MensagemRetorno from '@/view/MensagemRetorno'
import ModalLoading from '@/view/ModalLoading'

export default {
  name: 'App',
  components: {
    PaginaInicial,
    BarraLateral,
    Instalacao,
    NovoModulo,
    MensagemRetorno,
    ModalLoading
  },
  data() {
    return {
      componente : 'PaginaInicial'
    }
  },
  methods: {
    viewSelecionada(selecao) {
     this.componente = selecao
    }
  }
  
}
</script>

<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/bulma@0.9.2/css/bulma.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bulma-helpers/0.4.0/css/bulma-helpers.min.css");
@import '~@fortawesome/fontawesome-free/css/all.css';

html {
   overflow-y: hidden !important;
}

.component{
  height: calc(100vh - 210px) !important;
  box-shadow: 0 0 10px rgb(207, 207, 207);
  border-radius: 4px;
  overflow: auto;
}

.campoScript {
    margin: 15px 0 15px 0;
    padding: 20px;
    width: 70%;
    float: left;
    color: white;
    background-color: black;
    border: 1px solid black;
    border-radius: 4px;
}
p {
    margin: 1em 0 1em 0 !important;
    user-select: none;
}
li {
    user-select: none;
}

.cabecalho {
  box-shadow: 10px 5px 5px rgb(190, 190, 190);
}
</style>
