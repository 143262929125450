<template>
    <div>
        <span class="title is-5 is-unselectable">Criação e build</span>
        <div class="column is-12 is-pulled-left">
            <p class="title is-6">Criação</p>
            <p>Para a criação de um novo módulo, na pasta components dentro da pasta src do projeto, deve-se criar um novo arquivo <b>.vue</b>.</p>
            <p>Todo o componente deve ficar nesse arquivo.</p>
            <p class="title is-6">Parâmetros</p>
            <p>Em vários casos, o componente necessita de parâmetros, devido ao fato de ser um componente reutilizável.</p>
            <p>Para esse utilitário, os componentes são passados via <b>props</b>. Eles devem ser do tipo string devido ao componente 
                ir para uma nova instância do VUE e com isso as validações de tipo se perdem. Os dados podem ser convertidos
                usando <b>JSON.stringify</b>.</p>
            <p>A propriedade, independente da forma escrita (snake case ou camel case), ao compilar será convertida para snake case, sendo assim ao utilizar
                o componente, tem que se atentar a esse caso.</p>
            <p class="title is-6">Build dos componentes</p>
            <p>O processo do build verifica a pasta components e todos os arquivos <b>.VUE</b> são convertidos, cada arquivo se torna um componente independente.</p>
            <p>Para realizar o processo, execute o comando "npm run build-wc" e será executada a linha abaixo:</p>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    <p>"build-wc": "vue-cli-service build --target wc --name atena-utils src/components/*.vue"</p>
                </div>
            </div>
            <p>Observe que na linha possui o campo "--name". O parâmetro deste campo, será inserido antes do nome do componente e em snake-case ficando: <b>atena-utils-meu-componente</b>.</p>
            <p>Nesse processo será gerado um arquivo único com nome de <b>atena-utils</b>.</p>
            <p class="title is-6">Build dos componentes modo assíncrono</p>
            <p>No build anterior, é gerado arquivo único, que será importado no início da aplicação. Com esse novo processo de build, será gerado o arquivo de entrada
                e cada componente irá para um arquivo separado, que só será importado quando o componente for utilizado.</p>
            <p>Para realizar o processo, execute o comando <b>npm run build-wc</b> e será executada a linha abaixo:</p>
             <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    <p>"build-wc-async": "vue-cli-service build --target wc-async --name atena-utils src/components/*.vue"</p>
                </div>
            </div>
            <p class="title is-6">Build da página de tutorial</p>
            <p>Para realizar esse build, execute o comando <b>npm run build</b>. Será gerado o arquivo com a página completa para ser incluído no projeto principal Atena.</p>
             <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    <p>"build": "vue-cli-service build --target wc --name atena-utils src/components/*.vue"</p>
                </div>
            </div>
        </div>
        
        
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>