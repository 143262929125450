<template>
    <div>
        <div>
            <span class="title is-5 is-unselectable">Mensagens Retorno</span>
        </div>
        <div class="column is-12 is-pulled-left">
            <p>Esse componente foi criado para ser utilizado nas trocas de mensagens com os serviços.</p>
            <p>O componente possui um parâmetro que indica o seu tipo e que muda sua cor para os casos de  
                <b class="has-text-primary">Sucesso</b>,
                <b class="has-text-danger">Erro</b>,
                <b class="has-text-warning">Alerta</b> ou 
                <b class="has-text-info">Informação</b>
            </p>
            <article class="message column is-5 is-success has-margin-15">
                <div class="message-header">
                    <span class="is-capitalized">Sucesso</span>
                </div>
                <div class="message-body">
                    <span> Nesse campo vai a mensagem recebida.</span>
                    <button class="button is-pulled-right is-success has-margin-top-35">Ok (10)</button>
                </div>
            </article>
        </div>
       <div class="column is-12 is-pulled-left">
            <span class="title is-6 is-unselectable">Instalação</span>
            <div class="has-margin-10">
                <p>Após a importação do script, conforme descrito na etapa de instalação, deve ser incluída a tag conforme abaixo:</p>
                <div class="campoScript has-margin-10">
                    {{tag}}
                </div>
            </div>
        </div>
       <div class="column is-12 is-pulled-left">
            <span class="title is-6 is-unselectable">Parâmetros</span>
            <div class="has-margin-10">
                <p>O componente recebe os dados via uma <b>Prop</b> de nome <b>'parametros-mensagem'</b> que é <b>obrigatória</b>.</p>
                <p>Nessa Prop, são enviadas 3 propriedades:</p>
                <ol class="has-margin-10 has-padding-left-15">
                    <li>exibirMensagem</li>
                    <li>tipoMensagem</li>
                    <li>textoMensagem</li>
                </ol>
                <p></p>
                <div class="column is-12 is-pulled-left is-paddingless has-margin-bottom-15">
                    <div class="campoScript has-margin-10">
                        {{tag2}}
                    </div>
                    <div class="is-pulled-left">
                        <p>Para que os parâmetros sejam renderizados corretamente, é necessário que seja passado para o "parametro-mensagem", o valor do tipo <b>string</b>.</p>
                        <p>Na conversão teste, foi utilizado o "JSON.stringify".</p>
                    </div>
                </div>
                <span class="title is-6 has-padding-top-15 is-unselectable">Parâmetro opcional</span>
                <div class="has-margin-top-15">
                    <p>Para o componente, existe mais um parâmetro opcional, o <b>tipo-crud</b>.</p>
                    <p>Os parâmetros esperados são: put, post, delete.</p>
                    <p>O impresso é feita validando o tipo da menagem e o valor da variável, tipoCrud.</p>
                    <p>Abaixo segue a validação e a mensagem gerada:</p>
                    <ol class="has-margin-10 has-padding-left-15">
                        <li>Mensagem de "erro" e variável vazia: <b>Ocorreu um erro ao processar a requisição</b></li>
                        <li>Mensagem de "erro" e variável com a string "delete": <b>Não foi possível excluir o cadastro!</b></li>
                        <li>Mensagem de "sucesso" e variável com a string "put": <b>Atualizado com sucesso!</b></li>
                        <li>Mensagem de "sucesso" e variável com a string "post": <b>Cadastrado com sucesso!</b></li>
                        <li>Mensagem de "sucesso" e variável com a string "delete": <b>Deletado com sucesso!</b></li>
                    </ol>
                </div>
            </div>
            <div class="column is-12 is-pulled-left">
                <span class="title is-6 is-unselectable">Exibir / Ocultar o componente</span>
                <div >
                    <p>O processo é feito através da diretiva v-if, onde o componente será exibido ou ocultado.</p>
                     <div class="column is-12 is-pulled-left is-paddingless">
                        <div class="campoScript has-margin-10">
                            {{tag3}}
                        </div>
                    </div>
                    <p>O componente possui um contador no botão "OK" que dispara um evento com o nome "fecharMensagemRetorno".</p>
                    <p>Um exemplo de implementação é criar uma state global e alterar o valor da mesma.</p>
                    <p>Essa state pode ser criada usando o VUEX e alterando o valor via commit.</p>
                    <p>O primeiro passo é criar uma função para fechar a mensagem:</p>
                    <div class="column is-12 is-pulled-left is-paddingless">
                        <div class="campoScript has-margin-10">
                            <div>fecharMensagem(evento) {</div>
                            <div class="has-margin-left-20">store.commit('fecharMensagem', evento)</div>
                            <div>},</div>
                        </div>
                    </div>
                    <p>em segundo, no ciclo de vida create, incluir a escuta do evento que irá chamar a mensagem.</p>
                    <div class="campoScript has-margin-10">
                        <div>created() {</div>
                        <div class="has-margin-left-20">window.addEventListener('fecharMensagemRetorno', (e) => {</div>
                        <div class="has-margin-left-40">store.commit('fecharMensagem', e.detail)</div>
                        <div class="has-margin-left-20">})</div>
                        <div>}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tag: `<atena-utils-mensagens-retorno />`,
            tag2: `<atena-utils-mensagens-retorno :parametros-mensagem='parametrosMensagem'/>`,
            tag3: `<atena-utils-mensagens-retorno v-if="variavel-da-state"/>`
        }
    }

}
</script>

<style>
.message {
    height: 170px;
}
</style>