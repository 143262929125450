<template>
   <div>
        <span class="title is-5 is-unselectable">Instalação</span>
        <div class="column is-12 is-pulled-left">
            <p>Como a util é utilizada no ambiente de Web Component, o script deve ser incluído no arquivo APP.vue</p>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    {{ linkCDN }}
                </div>
            </div>
            <p>No arquivo main.js, colocar a exceção para os módulos e instanciar o VUE como global.</p>
            <p>Uma observação importante é que todos os módulos começam com <b>atena-utils</b></p>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    <span>window.Vue = require('vue');</span>
                    <br>
                    {{ elementosIgnorados }}
                </div>
            </div>
            <p>Feito isso, o módulo já pode ser utilizado em qualquer lugar.</p>
            <div class="column is-12 is-pulled-left is-paddingless">
                <div class="campoScript">
                    {{ moduloSitax }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
     data() { 
        return {
            linkCDN: '<script type="module" src="https://cdn-erpforme.alterdata.com.br/nimbus/atena/mf/atena-utils/atena-utils.min.js"/>',
            elementosIgnorados: `Vue.config.ignoredElements = ['atena-utils-meu-modulo']`,
            moduloSitax: `<atena-utils-meu-modulo />`
        }
    }
}
</script>