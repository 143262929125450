<template>
    <div>
        <div>
            <span class="title is-5 is-unselectable">Modal Loading</span>
        </div>
        <div class="column is-12 is-pulled-left">
            <p>Esse componente foi criado para ser incluído nos momentos de espera de carregamento ou do retorno dos dados nas requisições.</p>
            <p>Abaixo está um exemplo do componente:</p>
            <div class="control modal-load has-margin-20">
                <div class="loader-wrapper is-active">
                    <span class="is-size-3 column is-5">Realizando Procedimento</span>
                    <div class="loader is-loading has-margin-right-20"></div>
                </div>
            </div>
        </div>
         <div class="column is-12 is-pulled-left">
            <span class="title is-6">Instalação</span>
            <div class="has-margin-10">
                <p>Após a importação do script conforme descrito na etapa de instalação, deve ser incluída a tag abaixo:</p>
                <div class="campoScript">
                    {{tag}}
                </div>
            </div>
        </div>
        <div class="column is-12 is-pulled-left">
            <span class="title is-6">Exibir / Ocultar o componente</span>
            <div class="has-margin-10">
                <p>O processo é feito através da diretiva v-if, onde o componente será exibido ou ocultado.</p>
                <p>Um exemplo de implementação é criar uma state global e alterar o valor da mesma.</p>
                <p>Essa state pode ser criada usando o VUEX e alterando o valor via commit.</p>
                <div class="campoScript">
                    {{tag2}}
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
export default {
    data() {
        return {
            tag: `<atena-utils-modal-loading />`,
            tag2: `<atena-utils-modal-loading v-if='variavel-da-state'/>`
        }
    }

}
</script>

<style lang="scss">

    .modal-load {
        height: 200px;
        width: 550px !important;
        border: 1px solid white;
        box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    }

    .loader-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: #fff;
        opacity: 10;
        z-index: 999;
        transition: opacity .3s;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;

        .loader {
            height: 180px;
            width: 180px;
        }

        &.is-active {
            opacity: 1;
            z-index: 999;
        }
    }

</style>